.landing-page {
  margin-bottom: 30px;
}

.landing-title-component {
  padding-top: 25px;
  width: 100%;
  font-size: 64px;
  text-align: center;
  letter-spacing: 1px;
  font-family: Didot, serif;
  margin-bottom: 0;
}
.landing-title-component2 {
  width: 100%;
  font-size: 64px;
  text-align: center;
  letter-spacing: 1px;
  font-family: Didot, serif;
  margin-top: 0;
  margin-bottom: 44px;
}

.landing-subtitle-component {
  width: 50%;
  font-size: 20px;
  text-align: center;
  letter-spacing: 1px;
  font-family: Didot, serif;
  margin-top: 0;
  margin: auto;
}

.landing-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .landing-title-container {
    padding-top: 15vh;
    padding-bottom: 15vh;
  }
}

@media screen and (max-width: 880px) {
  .landing-title-container {
    flex-direction: column;
    gap: 40px;
  }
  .landing-page {
    margin-bottom: 300px;
  }
}

@media screen and (max-width: 520px) {
  .landing-title-container {
    flex-direction: column;
    gap: 40px;
    padding-top: 10vh;
    padding-bottom: 25vh;
  }
  .landing-page {
    margin-bottom: 300px;
  }
  .landing-title-component {
    font-size: 25px;
    width: 100%;
  }
  .landing-title-component2 {
    font-size: 25px;
    margin-bottom: 20px;
    width: 100%;
  }
  .landing-subtitle-component {
    width: 80%;
    font-size: 14px;
  }
}
