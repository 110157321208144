.nav-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 120px;
  width: 100%;
}

.nav-titles {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: VogueAvantGarde, helvetica, sans-serif;
  font-size: 15px;
  font-style: light;
  font-stretch: extra-expanded;
  letter-spacing: 1px;
  width: 100%;
}

.nav-titles li {
  display: inline;
  margin-left: 30px;
  margin: 0 30px;
  width: 100%;
  cursor: pointer;
}

.contact-button {
  text-decoration: none;
  color: inherit;
  font-weight: normal;
  background-color: transparent;
  border: none;
  font-size: 15px;
  padding: 0;
  cursor: pointer;
}

.contact-button:hover,
.contact-button:focus {
  border: none;
  outline: none;
}

@media screen and (max-width: 880px) {
  .nav-titles {
    font-size: 100%;
  }
  .wrapper {
    width: 100%;
  }
  .contact-button {
    font-size: 100%;
  }
}

@media screen and (max-width: 520px) {
  .nav-titles {
    width: 100%;
    font-size: 50%;
    padding-right: 5%;
  }
  .wrapper {
    width: 100%;
  }
  .contact-button {
    width: 100%;
    font-size: 50%;
    padding-right: 5%;
  }
}
