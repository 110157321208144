body {
  margin: 0;
  font-family: VogueAvantGarde, helvetica, sans-serif;
}

code {
  font-family: VogueAvantGarde, helvetica, sans-serif;
}

@font-face {
  font-family: "adobe-garamond-pro";
  src: url("https://use.typekit.net/whh7phj.css");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Didot";
  src: url("./fonts/Didot.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DidotBold";
  src: url("./fonts/DidotBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DidotItalic";
  src: url("./fonts/DidotItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DidotTitle";
  src: url("./fonts/DidotTitle.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
