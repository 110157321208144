.about-page {
  margin-top: 60px;
}

.about-me-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-bottom: 100px;
}

.about-me-component {
  display: flex;
  padding: 20px 20px 20px 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.bio-image {
  border-radius: 4px;
  border-color: transparent;
  max-width: 100%;
}

.bio-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bio-text {
  padding: 10px 25px;
  font-size: 17px;
  text-align: center;
  letter-spacing: 1px;
  font-family: Didot, serif;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: center;
}

.bio-line {
  border: 1px solid #77613cab;
  width: 50%;
}

@media screen and (min-width: 880px) {
  .about-me-wrapper {
    padding-left: 150px;
    padding-right: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .about-me-component {
    flex-direction: column;
    padding: 0 40px;
  }
  .about-me-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 880px) {
  .about-me-component {
    flex-direction: column;
    padding: 0 40px;
  }
  .bio-text-container {
    width: 100%;
    padding-top: 20px;
  }
  .bio-text {
    width: 100%;
    font-size: 16px;
  }
  .bio-line {
    width: 80%;
  }
  .about-me-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 520px) {
  .about-page {
    margin-top: 30px;
  }
  .about-me-component {
    padding: 0 20px;
  }
  .bio-image {
    width: 100%;
  }
  .bio-text {
    width: 100%;
    font-size: 15px;
  }
  .bio-line {
    width: 100%;
  }
  .about-me-wrapper {
    flex-direction: column;
  }
}
