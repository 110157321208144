/* .modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  padding: 20px;
}

.modal-border {
  border: 1px solid #77613cab;
  width: 100%;
  max-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 20px;
  border-radius: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1001;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal-number {
  width: 100%;
  font-size: 6vw;
  text-align: left;
  letter-spacing: 1px;
  font-family: Didot, serif;
  margin-bottom: 0;
}

.modal-header {
  padding-top: 25px;
  width: 100%;
  font-size: 6vw;
  text-align: center;
  letter-spacing: 1px;
  font-family: Didot, serif;
  margin-bottom: 0;
}

.modal-subtitle {
  font-size: 4vw;
  color: #77613cab;
  margin-top: 0;
  padding-top: 25px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-family: Didot, serif;
  margin-bottom: 0;
}

.modal-content,
.modal-content2,
.modal-content3,
.modal-content4,
.modal-content5,
.modal-content6,
.modal-content7 {
  font-size: 1.5em;
  color: #77613cab;
  margin-top: 15px;
  margin-bottom: 0;
  font-family: Didot, serif;
  text-align: center;
  width: 100%;
}

.modal-button {
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 2em;
  color: #77613cab;
  font-weight: bold;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.modal-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .modal-number,
  .modal-header,
  .modal-subtitle {
    font-size: 5vw;
  }

  .modal-header-container {
    padding: 0 10px;
  }

  .modal-content,
  .modal-content2,
  .modal-content3,
  .modal-content4,
  .modal-content5,
  .modal-content6,
  .modal-content7 {
    font-size: 1em;
  }

  .modal-button {
    font-size: 1.5em;
  }
} */

/* Second Option Modal styles */
.full-screen-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  padding: 2em;
  margin-top: 4em;
  margin-bottom: 4em;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-border {
  width: 100%;
}

.modal-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.modal-number {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  font-family: Didot, serif;
  color: #77613cab;
}

.modal-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.modal-button:hover {
  color: #000;
}

.modal-header {
  font-size: 30px;
  font-weight: bold;
  margin: 25px 0;
  font-family: Didot, serif;
  color: #77613cab;
}

.modal-subtitle {
  font-size: 18px;
  margin: 15px 0;
}

.modal-content,
.modal-content2,
.modal-content3,
.modal-content4,
.modal-content5,
.modal-content6,
.modal-content7 {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.5;
}
input:focus,
textarea:focus {
  border: 1px solid #fff !important; /* Custom focus border color */
  outline: none !important;
  box-shadow: none !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Resetting any focus styles */
* {
  box-shadow: none !important;
  outline: none !important;
}

.full-screen-modal input,
.full-screen-modal textarea {
  border: 1px solid #fff;
}

.full-screen-modal input:focus,
.full-screen-modal textarea:focus {
  border: 1px solid #fff !important;
  outline: none !important;
  box-shadow: none !important;
}
