.services-container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin-left: 20px; */
  gap: 40px;
}

.services-container-one,
.services-container-two,
.services-container-three {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.services-card {
  background-color: #eeeae351;
  color: #77613cab;
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
  font-size: 1em;
  line-height: 1.5;
  height: 325px;
  width: 300px;
  border-radius: 8px;
  position: relative;
}

.services-number {
  font-size: 1.5em;
  color: #77613cab;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
}

.services-header {
  font-size: 1.68em;
  color: #77613cab;
  margin-top: 0;
  margin-bottom: 0;
}

.services-text-component {
  font-size: 1em;
  color: #77613cab;
  margin-top: 0;
  margin-bottom: 0;
}

.services-button {
  font-weight: bold;
  position: absolute;
  padding-top: 5px;
  bottom: 5px;
  cursor: pointer;
}

@media screen and (max-width: 880px) {
  .services-container-one,
  .services-container-two,
  .services-container-three {
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .services-card {
    width: 500px;
  }
}

@media screen and (max-width: 520px) {
  .services-container-one,
  .services-container-two,
  .services-container-three {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    align-self: center;
  }
  .services-card {
    width: 350px;
    font-size: 14px;
    height: 250px;
  }
  .services-header,
  .services-number {
    font-size: 20px;
  }
}
