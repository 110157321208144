.contact-full-screen-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.contact-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.contactForm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form {
  width: 100%;
}

.contact-form-div,
.contact-form-div2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.contact-form-div label,
.contact-form-div2 label {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.contact-form-div input,
.contact-form-div2 textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.contact-form-div input:focus,
.contact-form-div2 textarea:focus {
  border: 1px solid #77613cab;
  outline: none;
}

.contact-form-div2 textarea {
  resize: vertical;
  min-height: 100px;
}

button[type="submit"] {
  background-color: #77613cab;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #77613cab;
}

.success-message {
  color: #77613cab;
  font-weight: bold;
  margin-top: 20px;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}
