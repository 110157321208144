.contact-container {
  text-align: left;
  padding-left: 200px;
  background-color: black;
  color: white;
  height: 200px;
  padding-top: 40px;
  font-family: adobe-garamond-pro, serif;
  font-weight: 600;
  letter-spacing: 2px;
}

#copyright {
  text-align: center;
  border-top: 2px solid #1f1f1f;
  padding-top: 100px;
  padding-bottom: 30px;
  margin-bottom: 0px;
  width: calc(100% - 80px);
  max-width: 1400px;
  margin: 0 auto;
}

#contact {
  background-color: black;
  color: #575757;
}

.icon-image {
  padding-right: 20px;
}

.brand-3 {
  font-family: adobe-garamond-pro, serif;
  font-weight: 600;
  font-size: 35px;
  margin-bottom: 3px;
}

.icon-image {
  height: 75px;
}

@media screen and (max-width: 880px) {
  #contact {
    width: 100%;
  }
  #copyright {
    width: 100%;
  }
  .contact-container {
    height: 50vh;
    align-self: left;
    left: 0;
    padding-left: 20px;
  }
  .brand-3 {
    max-width: 80vh;
    align-self: left;
    left: 0;
  }
  .icon-image {
    max-height: 50px;
    align-self: left;
    left: 0;
  }
}

@media screen and (max-width: 520px) {
  #contact {
    width: 100%;
  }
  #copyright {
    width: 100%;
    font-size: 60%;
    padding-top: 50px;
  }
  .contact-container {
    height: 40vh;
    align-self: left;
    left: 0;
    padding-left: 10px;
  }
  .brand-3 {
    max-width: 80vh;
    align-self: left;
    left: 0;
  }
  .icon-image {
    max-height: 40px;
    align-self: left;
    left: 0;
  }
}
