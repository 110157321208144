.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.title-component {
  position: relative;
  padding: 10px 0;
  width: 80%;
  font-size: 25px;
  font-weight: 200;
  text-align: left;
  letter-spacing: 1px;
}

.title-container {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 20px;
}

.title-container-1 {
  display: flex;
  justify-content: center;
}

.title-component::before,
.title-component::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
}

.title-component::before {
  top: 0;
  border-bottom: none;
}

.title-component::after {
  bottom: 0;
  border-top: none;
}

@font-face {
  font-family: "adobe-garamond-pro";
  src: url("https://use.typekit.net/whh7phj.css");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 520px) {
  .title-container {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .title-component {
    font-size: 20px;
  }
}
