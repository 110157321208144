.nav-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 100;
  margin: 0;
  width: 100%;
  /* padding: 10px; */
  box-sizing: border-box;
}

.brand-1,
.brand-2 {
  text-align: center;
  font-family: adobe-garamond-pro, serif;
  font-weight: 600;
  font-size: 50px;
  margin: 0;
}

.brand-1 {
  padding-top: 20px;
  font-size: 40px;
  letter-spacing: 2px;
}

.brand-2 {
  padding-bottom: 20px;
  font-size: 20px;
  letter-spacing: 1px;
}

.modal-icon,
.modal-icon2 {
  align-self: start;
  display: flex;
  background-color: transparent;
  border: none;
}

.nav-drawer {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: bottom 0.7s ease-in-out;
  z-index: 101;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
}

.nav-drawer.open {
  bottom: 0;
}

@media screen and (max-width: 880px) {
  .nav-heading {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .nav-heading {
    width: 100%;
  }
}
